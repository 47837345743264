<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0"> 
        <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
        <div class="row justify-content-center">
        <h3 class="mb-10 font-weight-bold text-dark">
        <i class="fa fa-truck" style="font-size: 20px"></i>
          DRIVER UPDATE
        </h3>
        <div class="col-xl-12">
        <el-form ref="form" :model="form" auto-complete="false">
          <el-row>
          <el-col :span="6" :offset="8">
            <el-form-item label="ORDER NUMBER" prop="order_number">
              <el-select clearable filterable remote v-model="order_id" 
                placeholder="Select Order"  
                style="width:100%" :allow-create="true"   
               >                            
                <el-option v-for="delivery in this.list" :key="delivery.id" :label="delivery.order.order_number" :value="delivery.id" ></el-option>
              </el-select>
            <span class="form-err" >{{order_error}}</span>
            </el-form-item>
            </el-col>
            <!-- <el-col :span="4" :offset="1">
            <el-form-item label="INVOICE NUMBER" prop="order_number">
              <el-select clearable filterable remote v-model="order_id" 
                placeholder="Select Invoice"  
                style="width:100%" :allow-create="true"   
               >                            
               <el-option v-for="delivery in this.list" :key="delivery.id" :label="'Invoice - ' +delivery.invoice.invoice_number" :value="delivery.id" ></el-option>
              </el-select>
            <span class="form-err"  >{{invoice_error}}</span>
            </el-form-item>
            </el-col> -->
            <el-col :span="2" :offset="1" class="mt-4">
                    <el-button  type="primary" icon="el-icon-edit-outline" round size="medium" @click="addDocument()">open</el-button>
            </el-col>
          </el-row>
        </el-form>
        </div>
        </div>
        </div>
      </div>
    </div> 
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllDeliveryorder} from "@/api/deliveryStatus";
import deliveryDocument from '@/components/OrderDelivery/add_document';
import { globalEvents } from '@/utils/globalEvents';
  export default {
    name: "pos-order",
    data() {
      return {
        list: [],
        loading:false,
        order_id : null,
        order_error:'',
        invoice_error:''
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Driver Update"},
      ]);
      globalEvents.$on('document', doc => {
        this.list = [];
        this.order_id = null;
        this.fetchData();   
               if (doc) {
                this.fetchData();          
               }  
          })
    },
    created(){
     this.fetchData();
    },
    methods:{
      addDocument(){
        let delivery = [];
        this.order_error = '';
        this.invoice_error = '';
        if(this.order_id){
        this.list.map((del)=>{
          if(this.order_id == del.id){
            delivery = del;
            this.$modal.show(deliveryDocument, {
              order_id : this.order_id,
              delivery :delivery,
              page_name:'driver update'}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '700px' })     
          }else{
            this.order_error = "Please Enter Valid Order Number"
          }
        })
       
        }else{
          this.order_error = "Please Select Order Number";
          this.invoice_error = "Please Select Invoice Number" ; 
        }
      },
      
      fetchData() {
        this.loading = true
        getAllDeliveryorder().then(response => {     
          this.list = response.data.data 
          this.loading = false 
          }
        )},
       
     
     
    },
    
  }
</script>